import React from "react"

import Seo from "../components/seo"
import Hero, { HeroContent } from "../components/hero"
import LayoutDefault from "../layouts/default"
import { Row, Content, Article } from "../components/article"

const AboutPage = () => {
  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/about", name: "О сайте" },
  ]

  const content = '<p>Здесь публикуются лучшие способы заработка в интернете, Web разработка, WordPress, SEO, SMO, фриланс, IT новости и технологии, практические советы и рекомендации.</p>'

  return (
    <LayoutDefault>
      <Seo title="О сайте" description="Страница О сайте" url="/about"/>
      <Hero breadcrumbs={breadcrumbList} backLink="/">
        <HeroContent>
          <h1 className="heading">О сайте</h1>
        </HeroContent>
      </Hero>
      <Row>
        <Content>
          <Article dangerouslySetInnerHTML={{ __html: content }}/>
        </Content>
      </Row>
    </LayoutDefault>
  )
}

export default AboutPage